// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".L2_Sb6mflEi16_oV2YVG{padding:16px;border-radius:2px}.B8sfGqDJhQceAfnXiSy7{background:var(--secondary-background)}.F0HayptUvv7c52TJhdxs{width:100%}.JnsnOIT70ZLiST_XRSdM:hover{background:var(--hover-selected)}.theme-dark .RrgUBvnBEvsmQUEhgr8Q{border:var(--border-weak)}.theme-light .RrgUBvnBEvsmQUEhgr8Q{border:var(--border-weak)}.theme-dark .QyFmu4r6x5RA33haU43n{box-shadow:0 4px 10px rgba(0,0,0,.6)}", "",{"version":3,"sources":["webpack://./components/GBlock/Block.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iBAAA,CAEA,sBACE,sCAAA,CAGF,sBACE,UAAA,CAGF,4BACE,gCAAA,CAIJ,kCACE,yBAAA,CAGF,mCACE,yBAAA,CAGF,kCACE,oCAAA","sourcesContent":[".root {\n  padding: 16px;\n  border-radius: 2px;\n\n  &--withBackground {\n    background: var(--secondary-background);\n  }\n\n  &--fullWidth {\n    width: 100%;\n  }\n\n  &--hover:hover {\n    background: var(--hover-selected);\n  }\n}\n\n:global(.theme-dark) .root_bordered {\n  border: var(--border-weak);\n}\n\n:global(.theme-light) .root_bordered {\n  border: var(--border-weak);\n}\n\n:global(.theme-dark) .root_shadowed {\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "L2_Sb6mflEi16_oV2YVG",
	"root--withBackground": "B8sfGqDJhQceAfnXiSy7",
	"root--fullWidth": "F0HayptUvv7c52TJhdxs",
	"root--hover": "JnsnOIT70ZLiST_XRSdM",
	"root_bordered": "RrgUBvnBEvsmQUEhgr8Q",
	"root_shadowed": "QyFmu4r6x5RA33haU43n"
};
export default ___CSS_LOADER_EXPORT___;
