// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hIaTKzSpKwnciwBsbA5g{margin-bottom:24px;margin-top:24px}", "",{"version":3,"sources":["webpack://./components/PluginConfigPage/parts/ConfigurationForm/ConfigurationForm.module.css"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,eAAA","sourcesContent":[".info-block {\n  margin-bottom: 24px;\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-block": "hIaTKzSpKwnciwBsbA5g"
};
export default ___CSS_LOADER_EXPORT___;
