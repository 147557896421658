import { AppEvents, TimeZone } from '@grafana/data';
// @ts-ignore
import appEvents from 'grafana/app/core/app_events';

export function openNotification(message: React.ReactNode) {
  appEvents.emit(AppEvents.alertSuccess, [message]);
}

export function getCurrentDashboardUid(): string {
  const url = window.location.toString();

  const matches = new URL(url).pathname.match(/\/d\/([^/]+)/);
  if (!matches) {
    throw new Error(`Can't get current dashboard uid. If it's a new dashboard, please save it first.`);
  } else {
    return matches[1];
  }
}

export function convertTimestampToDate(timestamp?: number): string {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  return timestamp ? new Date(timestamp).toLocaleString('en-GB', options) : '-';
}

export function convertTimeZoneTypeToName(timeZone: TimeZone): string {
  switch (timeZone) {
    case 'utc':
      return 'Etc/UTC';
    case 'browser':
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    default:
      return timeZone;
  }
}
