import { ExportTask } from '../panels/corpglory-dataexporter-panel/types';

import appEvents from 'grafana/app/core/app_events';
import { AppEvents } from '@grafana/data';

import axios from 'axios';
import * as _ from 'lodash';

export const API_HOST = `${window.location.protocol}//${window.location.host}/`;
export const API_PROXY_PREFIX = 'api/plugin-proxy/corpglory-dataexporter-app';
export const API_PATH_PREFIX = '/api';

const instance = axios.create();

instance.interceptors.request.use(function (config) {
  config.validateStatus = (status) => {
    return status >= 200 && status < 300; // default
  };

  return {
    ...config,
  };
});

interface RequestConfig {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS';
  params?: any;
  data?: any;
  withCredentials?: boolean;
  validateStatus?: (status: number) => boolean;
}

export const queryApi = async <RT = any>(path: string, config: RequestConfig) => {
  const { method = 'GET', params, data, validateStatus } = config;

  const url = `${API_PROXY_PREFIX}${API_PATH_PREFIX}${path}`;

  const response = await instance({
    method,
    url,
    params,
    data,
    validateStatus,
  });

  return response.data as RT;
};

export async function getTasks(dashboardUid: string): Promise<ExportTask[]> {
  return queryApi<ExportTask[]>('/task', { params: { dashboardUid } });
}

export async function deleteTask(taskId?: string): Promise<void> {
  if (_.isEmpty(taskId)) {
    console.warn(`can't delete task without taskId`);
    return;
  }
  await queryApi<ExportTask[]>('/task', { method: 'DELETE', data: { taskId } });
}

export async function getStaticFile(filename?: string): Promise<void> {
  if (_.isEmpty(filename)) {
    appEvents.emit(AppEvents.alertWarning, ['Data Exporter', 'Can`t download a file without filename']);
    console.warn(`can't download file without filename`);
    return;
  }

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = `${API_PROXY_PREFIX}${API_PATH_PREFIX}/static/${filename}.csv`;
  link.target = '_blank';
  link.download = `${filename}.csv`;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}
